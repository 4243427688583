import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Flex, BodyWrapper, H6, Caption1, Caption2 } from '@components/atoms'
import {
  InplamArtistListItem,
  InplamArtistListItemSkeleton,
  PointBoxSkeleton,
} from '@components/molecules'
import {
  LoginPopup,
  BurgerMenu,
  LatestAlbumList,
  PlamChart,
  Footer,
  HomeInPlamList,
  ProgressingRewardList,
  AppDownloadPopup,
  LikePopup,
  NoticePopup,
} from '@components/organisms'
import { Observer, observer } from 'mobx-react'

import { move_btn_img_white, contact_banner } from '@images'
import LikeArtistList from '@components/organisms/List/LikeArtistList'
import PopularTrackList from '@components/organisms/List/PopularTrackList'
import LikeTrackList from '@components/organisms/List/LikeTrackList'
import { contact_banner2, move_btn_img } from '@images/index'
import {
  POST_MESSAGES,
  getIsWebApp,
  sendWTAPostMessage,
} from '@utils/postMessage'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'

const ContactBanner = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 13px 20px;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  background-image: linear-gradient(
    to right,
    #f78ca0 0%,
    #f9748f 19%,
    #fd868c 60%,
    #fe9a8b 100%
  );
`

const HomeInplamArtistList = styled(Flex)`
  position: relative;
  margin-left: -8px;
  flex-wrap: wrap;
  div + div {
    margin: 0 4px 8px 4px;
  }
  div:first-child {
    margin: 0px 4px;
  }

  max-height: 90px;
  overflow: hidden;
`
const UserCurrentPointBox = styled(Flex)`
  padding: 0px 12px 0px 16px;

  width: 100%;
  height: 48px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 8px;

  border-radius: 4px;
  border: solid 1px #f4f4f4;
  cursor: pointer;
`

const BottomGradient = styled.div`
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

const HomeTemplate = ({
  likedAristList,
  likedTrackList,
  popularTrackList,
  noticePopupList,
  currentUser,
  fetchPlamChart,
  latestAlbumList,
  rewardList,
  plamChartList,
  latestActicleList,
  fetchRewardList,
  fetchlatestActicleList,
  totalInplamList,
  history,
  onPressMoreTrackList,
  onPressMorePopularTrackList,
  likedTrackSortBy,
  handleChangeLikedTrackSortBy,
  popularTrackSortBy,
  handleChangePopularTrackSortBy,
}) => {
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [showDownloadPopup, setShowDownloadPopup] = useState(false) // 앱 다운로드 팝업
  const [showNoticePopup, setShowNoticePopup] = useState(false) // 공지사항 팝업
  const { authStore, loadingStore } = useStore()
  const { isInitialLoading, handlerObj, isLoading } = loadingStore

  useEffect(() => {
    const isWebApp = getIsWebApp({ navigator })

    if (noticePopupList?.length > 0 && !isWebApp) {
      setShowNoticePopup(true)
    }
  }, [noticePopupList])

  return (
    <>
      <BurgerMenu isHome isOpen={isOpen} setIsOpen={setIsOpen} />
      <BodyWrapper style={{ minHeight: '100vh' }}>
        {isShowLoginPopup && (
          <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
        )}
        {showDownloadPopup && (
          <AppDownloadPopup handleClose={() => setShowDownloadPopup(false)} />
        )}
        {showNoticePopup && (
          <NoticePopup
            noticePopupList={noticePopupList}
            handleClose={() => setShowNoticePopup(false)}
            type={'notice'}
          />
        )}
        <Flex type="column" style={{ padding: '56px 0 0 0' }}>
          <Observer>
            {() => {
              if (authStore?.jsonWebToken) {
                if (isInitialLoading) {
                  return <PointBoxSkeleton />
                }

                return (
                  <UserCurrentPointBox
                    type={'row'}
                    justify={'space-between'}
                    align={'center'}
                    onClick={() => {
                      const isWebApp = getIsWebApp({ navigator })
                      if (isWebApp) {
                        sendWTAPostMessage({
                          window,
                          navigator,
                          postMessageData:
                            POST_MESSAGES.WTA_DATA.MOVE_POINT_HISTORY,
                        })
                      } else {
                        setShowDownloadPopup(true)
                      }
                    }}
                  >
                    <H6
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {`보유 포인트`}
                      <H6 type={'Bold'}>
                        {` ${convertToPricingComma(
                          currentUser?.currentPoint || 0,
                        )}p`}
                      </H6>
                    </H6>

                    <img
                      src={move_btn_img}
                      alt={'move_point_btn'}
                      style={{ width: '16px', height: '16px' }}
                    />
                  </UserCurrentPointBox>
                )
              }
              return null
            }}
          </Observer>
          {(rewardList?.length > 0 || isInitialLoading) && (
            <ProgressingRewardList
              progressingRewardList={rewardList}
              fetchRewardList={fetchRewardList}
              setShowDownloadPopup={setShowDownloadPopup}
            />
          )}
          {
            <Observer>
              {() => {
                if (currentUser && likedAristList?.length > 0) {
                  return <LikeArtistList list={likedAristList} />
                }
                return null
              }}
            </Observer>
          }
          <LatestAlbumList latestAlbumList={latestAlbumList} />
          <HomeInPlamList
            fetchlatestActicleList={fetchlatestActicleList}
            latestActicleList={latestActicleList}
          />
          <Flex type="column">
            <Observer>
              {() => {
                return (
                  <HomeInplamArtistList>
                    <BottomGradient onClick={() => history.push('/inplam')} />
                    {handlerObj?.['fetchTotalInplamList']?.isLoading
                      ? Array(20)
                          .fill(1)
                          .map((item, index) => (
                            <InplamArtistListItemSkeleton
                              key={`inplamArtistSkel-${index}`}
                            />
                          ))
                      : totalInplamList?.map(item => (
                          <InplamArtistListItem
                            item={item}
                            key={item._id}
                            onClick={() => {
                              if (item?.isInplamOpen) {
                                history.push(
                                  `/artist/${item.uniqueName}`,
                                  // `/inplam/${item.uniqueName}?offset=0&sortBy=createdAt`,
                                )
                              } else {
                                history.push(`/artist/${item.uniqueName}`)
                              }
                            }}
                          />
                        ))}
                  </HomeInplamArtistList>
                )
              }}
            </Observer>

            <Flex
              justify="center"
              align="center"
              style={{
                width: '100%',
                margin: 0,
                height: 56,
                cursor: 'pointer',
              }}
              onClick={() => history.push('/inplam')}
            >
              <H6>더보기</H6>
            </Flex>
          </Flex>
          {/* <PlamChart
            fetchPlamChart={fetchPlamChart}
            plamChartList={plamChartList}
          /> */}
        </Flex>
        <Observer>
          {() => {
            if (
              (currentUser && likedTrackList?.length > 0) ||
              (currentUser && isInitialLoading)
            ) {
              return (
                <LikeTrackList
                  onPressMoreTrackList={onPressMoreTrackList}
                  list={likedTrackList}
                  isLoading={isInitialLoading}
                  likedTrackSortBy={likedTrackSortBy}
                  handleChangeLikedTrackSortBy={handleChangeLikedTrackSortBy}
                />
              )
            }
            return null
          }}
        </Observer>
        <Observer>
          {() => {
            if (popularTrackList?.length > 0 || isInitialLoading || isLoading) {
              return (
                <PopularTrackList
                  onPressMorePopularTrackList={onPressMorePopularTrackList}
                  list={popularTrackList}
                  isLoading={isInitialLoading}
                  popularTrackSortBy={popularTrackSortBy}
                  handleChangePopularTrackSortBy={
                    handleChangePopularTrackSortBy
                  }
                />
              )
            }
            return null
          }}
        </Observer>
      </BodyWrapper>
      {/* <ContactBanner>
        <H6 color="#ffffff">아티스트 회원 가입하고, 내 데이터 수집하기!</H6>
        <Flex
          style={
            {
              alignItems: 'center',
              marginTop: '8px',
              cursor: 'pointer',
            }
          }
        >
          <Caption1 type="Regular">
            <a
              style={{ color: '#ffffff' }}
              href={'https://plam.kr/artist'}
              target="_blank"
              rel="noopener noreferrer"
            >
              아티스트 등록하기
            </a>
          </Caption1>
          <img
            src={move_btn_img_white}
            style={{ width: '12px', height: '12px' }}
            alt="move_icon"
          />
        </Flex>
      </ContactBanner> */}
      <a
        style={{ color: '#ffffff' }}
        href={'https://forms.gle/KoWFTKtXEpvaf5Av7'}
        target="_blank"
      >
        <img
          src={contact_banner2}
          style={{ width: '420px' }}
          alt="contact_banner"
        />
      </a>
      <Footer />
    </>
  )
}

export default observer(HomeTemplate)
